import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Classes, Overlay, Spinner } from "@blueprintjs/core";
import {
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    Button,
    PopoverPosition,
    Tag,
    Navbar,
    NavbarGroup,
    NavbarDivider,
    NavbarHeading, Icon, Alert
} from '@blueprintjs/core';

import { dialogStore, mainStoreContext } from '../../stores/index';

import { OAuth } from "../../utils";
import { Auth } from 'aws-amplify';
import { Alignment } from "@blueprintjs/core/lib/cjs/common/alignment";
import { appRedirect } from "../../utils/app-redirect";

import './main-nav.scss';
import { Logger } from "../../utils/logger";

export const MainNav = () => {
    const mainStore = useContext(mainStoreContext);
    let [openAlert, setOpenAlert] = useState(false);
    let [preloadingPage, setPreloadingPage] = useState(false);

    function handleOpen() {
        setOpenAlert(true)
    }
    function handleClose() {
        setOpenAlert(false)
    }

    return (
        <Navbar fixedToTop={true}>
            <NavbarGroup key="nav-logo" align={Alignment.LEFT}>
                <Popover position={PopoverPosition.BOTTOM_RIGHT} boundary="viewport" minimal={true}>
                    <Button className="nav-app-list" minimal={true} large={true} fill={false}>
                        <Icon icon="menu" iconSize={22} />
                    </Button>
                    <Menu>
                        <MenuItem icon="hand-right" text="Admin portal" onClick={() => {Logger.writeLog('click - link - switch to Admin portal'); appRedirect("admin-portal",setPreloadingPage); setPreloadingPage(true)} }/>
                    </Menu>
                </Popover>
                <NavbarHeading>
                    <Link className="docs-logo map-logo" to="/" />
                </NavbarHeading>
            </NavbarGroup>
            <NavbarGroup key="nav-info" align={Alignment.RIGHT}>
                <Button className="no-focus" icon="notifications" minimal={true} />
                <Button className="no-focus" icon="cog" minimal={true} />
                <Alert
                    confirmButtonText="Ok"
                    canOutsideClickCancel={true}
                    canEscapeKeyCancel={true}
                    isOpen={openAlert}
                    onConfirm={handleClose}
                    onClose={handleClose}
                >
                    <p>This function is developing.</p>
                </Alert>
                <NavbarDivider />
                <Popover position={PopoverPosition.RIGHT_TOP} boundary="viewport">
                    <Tag className="nav-user-info" round={true} icon="user" rightIcon="caret-right"
                        interactive={true}>{Auth.user.attributes ? Auth.user.attributes.email : ""}</Tag>
                    <Menu>
                        <MenuItem
                            icon={mainStore.theme === 'dark' ? 'flash' : 'moon'}
                            text={mainStore.theme === 'dark' ? 'Light Theme' : 'Dark Theme'}
                            onClick={mainStore.toggleTheme} />
                        <MenuDivider />
                        <MenuItem icon="key" text="Edit Profile"
                            onClick={() => {Logger.writeLog('click - button - Edit Profile - show dialog'); dialogStore.showAccountSetting = true}} />
                        <MenuItem icon="key" text="Change Password"
                            onClick={() => {Logger.writeLog('click - button - Change Password - show dialog'); dialogStore.showChangePassword = true}} />
                        <MenuDivider />
                        <MenuItem icon="log-out" text="Logout" onClick={() => {Logger.writeLog('click - button - Logout'); OAuth.logout()}} />
                    </Menu>
                </Popover>
            </NavbarGroup>
            <Overlay isOpen={preloadingPage}>
                <div className={Classes.DIALOG_CONTAINER}>
                    <Spinner />
                </div>
            </Overlay>
        </Navbar>

    );
};
