import Cryptr from "cryptr";

const secretKey = process.env.REACT_APP_SECRET_KEY;
export const Encryption = {
  encrypt(value) {
    const cryptr = new Cryptr(secretKey);
    return cryptr.encrypt(value);
  },
  decrypt(encryptedValue) {
    const cryptr = new Cryptr(secretKey);
    return cryptr.decrypt(encryptedValue);
  },
};
