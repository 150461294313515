import React from 'react';
import { Auth } from 'aws-amplify';
import { AmplifyGoogleButton } from '@aws-amplify/ui-react'
import { Card, Elevation } from "@blueprintjs/core";
import './login.scss';
import OxpayLogo from '../../assets/img/oxpay-logo.png'

export const Login = () => {

  return (
    <div className="login-page">
      <img src={OxpayLogo} className='logo' width={200} />
      <div className="login-section">
        <Card
          elevation={Elevation.TWO}
        >
          <AmplifyGoogleButton
            onClick={() => Auth.federatedSignIn({ provider: "Google" })}
          />
        </Card>

      </div>
      <footer className='footer'>
        <p>&#169; 2024 OxPay SG PTE LTD</p>
        <a href='#' className='contact-support'>Contact Support</a>
      </footer>
    </div>
  )
};